<template>
  <v-app>
    <!-- 2023-6-1 レスポンシブ対応 ヘッダー追加 start-->
    <v-app-bar
      color="rgb(0, 172, 125)"
      density="compact"
      class="d-block d-md-none"
      height="32px"
    >
      <!--<v-img
        class="cti-mo-icon mx-2"
        src="/cti_mo_logo.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>-->
    </v-app-bar>
    <!-- 2023-6-1 レスポンシブ対応 ヘッダー追加 end-->
    <v-container fluid class="py-0 fill-height">
      <v-row class="fill-height mx-0">
        <v-col cols="12" md="6" style="background-color: white">
          <v-row class="py-20">
            <v-card flat class="mx-auto">
              <v-row>
                <v-col cols="4" md="12" class="mx-auto pb-0 pb-md-3">
                  <v-img
                    src="/cti_mo_logo413.png"
                    class="cti-mo-icon mx-auto"
                    max-width="220px"
                  />
                </v-col>
                <v-col cols="12">
                  <v-card-title class="justify-center font-weight-bold">
                    <nobr>とうみレッツ号</nobr>
                  </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row
            v-if="$vuetify.breakpoint.smAndDown === false"
            class="py-20 mt-5 mb-5"
          >
            <v-card
              flat
              outlined
              class="mx-auto"
              style="border-color: white; border-size: 4px;"
            >
            </v-card>
          </v-row>
          <v-row>
            <nuxt />
          </v-row>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndDown === false"
          cols="12"
          md="6"
          class="fill-height map-container"
        >
          <GmapMap
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: true
            }"
            :center="centerCoordinates"
            :zoom="15"
            style="width: 100%;height: 100%"
          >
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { createComponent } from '@vue/composition-api'
import { getCenterCoordinates } from '~/components/cti/centerCoordinates'
export default createComponent({
  setup() {
    const centerCoordinates = getCenterCoordinates()
    return {
      centerCoordinates
    }
  }
})
</script>

<style>
.cti-mo-green {
  background-color: #00ac7d;
}
.cti-mo-icon {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
}
</style>
