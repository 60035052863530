import { Order } from '~/models/api/order'

export type User = {
  id: number
  last_name: string
  last_kana: string
  first_middle_name: string
  first_middle_kana: string
  home_location_name: string
  birthday: string
  phone_number: string | null
  phone_number_landline: string
  status_confirmed: boolean
  sex: string
  reminder_push_notification: boolean
  shared_taxi_approach_push_notification: boolean
  automatic_voice_call_notification: boolean
  shared_taxi_approach_webpush_notification: boolean
  bus_operation_status_push_notification: boolean
  email: string
  memo: string
  message_template: string
  home_location_lat?: number
  home_location_lng?: number
  reservation_remind: number
  message_notification: boolean
}

export class Passenger {
  readonly id: number
  readonly last_name: string
  readonly first_name: string
  readonly last_kana: string
  readonly first_kana: string
  readonly address: string
  readonly age: number
  readonly phone_number: string
  readonly status_confirmed: string
  readonly histories: Order[]
  readonly password: string
  readonly mail: string
  readonly birth: string | null
  readonly sex: string
  readonly memo: string | null
  readonly message_template: string | null
  readonly notificationRemindBooking: boolean
  readonly notificationTaxiMessage: boolean
  readonly notificationTaxiPhone: boolean
  readonly notificationWebPushMessage: boolean
  readonly notificationServiceStatus: boolean
  readonly rawUser: User
  readonly homeLocation: {
    lat: number
    lng: number
  }
  constructor(user: User) {
    this.id = user.id
    this.last_name = user.last_name
    this.first_name = user.first_middle_name
    this.last_kana = user.last_kana
    this.first_kana = user.first_middle_kana
    this.address = user.home_location_name
    this.age =
      user.birthday !== null
        ? new Date().getFullYear() - parseInt(user.birthday.split('-')[0])
        : -1
    this.phone_number =
      user.phone_number !== null
        ? user.phone_number
        : user.phone_number_landline
    this.status_confirmed = user.status_confirmed ? '完了' : '提出待ち'
    this.histories = []
    this.mail = user.email
    this.password = ''
    this.birth = user.birthday
    this.sex = user.sex
    this.memo = user.memo
    this.message_template = user.message_template
    this.notificationRemindBooking = user.reminder_push_notification
    this.notificationTaxiMessage = user.shared_taxi_approach_push_notification
    this.notificationTaxiPhone = user.automatic_voice_call_notification
    this.notificationWebPushMessage =
      user.shared_taxi_approach_webpush_notification
    this.notificationServiceStatus = user.bus_operation_status_push_notification
    this.rawUser = user
    this.homeLocation = {
      lat: user.home_location_lat!,
      lng: user.home_location_lng!
    }
  }
}

export const searchByPhone = ($axios: any, val: string) => {
  return $axios.$get(`/users/?phone=${val}`).then((res: User[]) => {
    return res
  })
}
export const searchByName = ($axios: any, val: string) => {
  return $axios.$get(`/users/?name=${val}`).then((res: User[]) => {
    return res
  })
}
export const getUser = ($axios: any, id: number) => {
  return $axios.$get(`/users/${id}`).then((user: User) => {
    return user
  })
}

// APIサーバに登録されているWebプッシュ通知のトークン取得
export const getApiSrvWebPushToken = ($axios: any) => {
  return $axios.$get(`/users/me/webpush_token/`)
}

// APIサーバにWebプッシュ通知のトークン投稿
export const postApiSrvWebPushToken = ($axios: any, token: string) => {
  const urlParams = new URLSearchParams({
    webpush_token: token
  })
  return $axios.$post(`/users/me/webpush_token/`, urlParams)
}

// APIサーバに登録されているWebプッシュ通知のトークン削除
export const deleteApiSrvWebPushToken = ($axios: any) => {
  return $axios.$delete(`/users/me/webpush_token/`)
}

// WebPushTokenが必要か判定
export const isNeedWebPush = (user: any) => {
  return (
    user.shared_taxi_approach_webpush_notification ||
    String(user.reservation_remind) === '5' ||
    user.message_notification
  )
}
